import React from 'react'

import Layout from '../components/layout'
import ScreenshotImage from '../components/screenshots/screenshotImage'
import ScreenshotImageHistory from '../components/screenshots/screenshotImageHistory'
import ScreenshotImageStory from '../components/screenshots/screenshotImageStory'
import ScreenshotImageOffline from '../components/screenshots/screenshotImageOffline'
import GooglePlayImage from '../components/googlePlayImage'
import AppStoreImage from '../components/appStoreImage'
import LogoImage from '../components/logoImageBW'
import CambodiaFlag from "../components/flags/cambodiaFlag"
import LaosFlag from "../components/flags/laosFlag"
import ThailandFlag from "../components/flags/thailandFlag"
import VietnamFlag from "../components/flags/vietnamFlag"
import IndonesiaFlag from "../components/flags/indonesiaFlag"
import FacebookLogo from "../images/facebook-logo.svg"
import InstagramLogo from "../images/instagram-logo.svg"
import IconOffline from "../images/offline-icon.svg"
import IconHistory from "../images/history-icon.svg"
import IconDaily from "../images/daily-icon.svg"

import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO />
    <div className="hero">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="hero__wrapper">
              <h1 className="title">
                <span className="title__block title__block--first">Les</span>
                <span className="title__block title__block--second">savoirs utiles</span>
                <span className="title__block title__block--third">aux voyageurs</span></h1>
              <p>Monsieur Denis est le compagnon indispensable des voyageurs. Chaque matin, vous recevez un savoir utile sur le pays que vous visitez&nbsp;!</p>
              <div className="hero__stores">
                <a href='https://play.google.com/store/apps/details?id=com.oama.mdenis&utm_source=landing&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' title="Installez l'application Monsieur Denis sur Android" className="hero__button">
                  <GooglePlayImage />
                </a>
                <a href='https://apps.apple.com/us/app/monsieur-denis/id1468230172?l=fr&ls=1' title="Installez l'application Monsieur Denis sur iOS" className="hero__button">
                  <AppStoreImage />
                </a>
              </div>
            </div>
          </div>
          <div className="col hero__image">
            <ScreenshotImage image="history-screenshot.png" />
          </div>
        </div>
      </div>
    </div>

    <div className="content container">
      <div className="row row--right">
        <div className="col col--small">
          <ScreenshotImageStory />
        </div>
        <div className="col col--large content__text">
          <div className="content__header">
            <img src={IconDaily} alt="Un savoir utile par jour" width="80"/>
            <h2>Un savoir utile par jour</h2>
          </div>
          <p>Choisissez votre pays et apprenez chaque jour quelque chose de nouveau. Tous les matins à 9h, vous recevez une alerte qui vous indique que votre anecdote est disponible&nbsp;!</p>
        </div>
      </div>
      <div className="row row--left">
        <div className="col col--large content__text">
          <div className="content__header">
            <img src={IconHistory} alt="Ton historique à portée de main" width="80"/>
            <h2>L’historique à portée de main</h2>
          </div>
          <p>Votre cerveau est en ébullition face à toutes les découvertes de votre périple&nbsp;? Pas de problème&nbsp;! Les anecdotes de Monsieur Denis sont stockées dans la bibliothèque de l’application et classées par pays pour les relire dès que vous le souhaitez.</p>
        </div>
        <div className="col col--small">
          <ScreenshotImageHistory />
        </div>
      </div>
      <div className="row row--right">
        <div className="col col--small">
          <ScreenshotImageOffline />
        </div>
        <div className="col col--large content__text">
          <div className="content__header">
            <img src={IconOffline} alt="Fonctionne aussi hors-ligne" width="80"/>
            <h2>Un accès hors connexion</h2>
          </div>
          <p>Parce qu’à l’autre bout du monde, internet peut être aussi rare qu’une baguette fraîche et croustillante, les savoirs sont disponibles hors ligne à tout moment de votre voyage.</p>
        </div>
      </div>

      <div className="content__countries">
        <h2>Pays aujourd'hui disponibles</h2>
        <ul>
          <li>
            <CambodiaFlag />
            <span>Cambodge</span>
          </li>
          <li>
            <LaosFlag />
            <span>Laos</span>
          </li>
          <li>
            <ThailandFlag />
            <span>Thaïlande</span>
          </li>
          <li>
            <VietnamFlag />
            <span>Viêtnam</span>
          </li>
          <li>
            <IndonesiaFlag />
            <span>Indonésie</span>
          </li>
        </ul>
      </div>
    </div>

    <div className="cta container">
      <LogoImage />
      <h3>Téléchargez l'application</h3>
      <div className="cta__stores">
        <a href='https://play.google.com/store/apps/details?id=com.oama.mdenis&utm_source=landing&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' className="cta__download" title="Installez l'application Monsieur Denis sur Android">
          <GooglePlayImage />
        </a>
        <a href='https://apps.apple.com/us/app/monsieur-denis/id1468230172?l=fr&ls=1' className="cta__download" title="Installez l'application Monsieur Denis sur iOS">
          <AppStoreImage />
        </a>
      </div>

      <div className="cta__social">
        <h4>Et retrouvez-nous sur les réseaux sociaux&nbsp;!</h4>
        <div>
          <a href="https://www.facebook.com/monsieurdenisapp" target="_blank" rel="noopener noreferrer" title="Suivez-nous sur Facebook">
            <img src={FacebookLogo} alt="Facebook Logo" width="40"/>
          </a>
          <a href="https://www.instagram.com/monsieurdenisapp/" target="_blank" rel="noopener noreferrer" title="Suivez-nous sur Instagram">
            <img src={InstagramLogo} alt="Instagram Logo" width="40"/>
          </a>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
